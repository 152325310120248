
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import { ChartData } from "chart.js";
import * as XLSX from "xlsx";

import Chart from "@/components/common/Chart.vue";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IIngredientScrappingFilter, IIngredientScrappingInfo, IIngredientScrappingInfoCSV } from "@common/ingredient";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

interface IngredientScrappingInfo extends IIngredientScrappingInfo {
	scrappingTime?: Date;
}

export default Vue.extend({
	name: "ScrappingTab",
	props: {
		stationId: String
	},
	components: {
		chart: Chart
	},
	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			search: "" as string,
			tableLoading: false as boolean,
			progressPercentage: 0 as number,
			headers: [
				new TableHeader("Ingredient Name", "start", true, "ingredientName"),
				new TableHeader("Scrapping Date", "start", true, "scrappingDate"),
				new TableHeader("Scrapping Time", "start", true, "scrappingTime"),
				new TableHeader("Quantity", "start", true, "qty"),
				new TableHeader("Cause", "start", true, "cause")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			autoCompleteChipsLimit: 10,
			scrappingItems: [] as IIngredientScrappingInfo[],
			filteredScrappingItems: [] as IngredientScrappingInfo[],
			filters: {
				grouping: store.state.dashboard.filters.grouping[0],
				ingredients: [] as string[],
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
				timeRange: {
					start: store.state.dashboard.timePresets.start[0],
					end: store.state.dashboard.timePresets.end[store.state.dashboard.timePresets.end.length - 1]
				},
				timezone: moment().format("Z"),
				cause: store.state.dashboard.filters.causes.ingredients[0]
			},
			menus: {
				dateRange: false,
				timeRange: false
			},
			selectedRange: 3,
			chartData: {
				labels: [],
				datasets: []
			} as ChartData
		};
	},
	created: async function () {
		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchOnlyUsedIngredients();
		}
		this.filters.ingredients = [this.ingredientSelectItems[0].value];
		await this.fetchData(this.$props.stationId);
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		ingredientSelectItems () {
			const ingredientSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const ingredient of this.store.getters.ingredients) {
				const item = {
					text: `(${ingredient.id}) ${ingredient.name}`,
					value: ingredient.id
				};

				if (_.findIndex(ingredientSelectItems, item) === -1) {
					ingredientSelectItems.push(item);
				}
			}

			return ingredientSelectItems;
		},
		icon () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) return "mdi-close-box";
			if (this.filters.ingredients.length !== this.ingredientSelectItems.length && this.filters.ingredients.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}

				return dateRange;
			}

			return "";
		},
		timeRange () {
			return `${this.filters.timeRange.start}, ${this.filters.timeRange.end}`;
		}
	},
	watch: {
		stationId: async function (newStationId: string | undefined) {
			await this.fetchData(newStationId);
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Request canceled by the user.");
				this.axiosSource = axios.CancelToken.source();
			}
		}
	},
	methods: {
		async fetchData (stationId: string | undefined) {
			this.tableLoading = true;
			this.progressPercentage = 0;
			let totalLoaded = 0;

			if (stationId) {
				try {
					let localScrappingItems: IIngredientScrappingInfo[] = [];
					for (const ingredient of this.filters.ingredients) {
						const data: IIngredientScrappingFilter = {
							grouping: this.filters.grouping,
							date: {
								from: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
								until: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
							},
							time: {
								start: this.filters.timeRange.start,
								end: this.filters.timeRange.end
							},
							ingredient,
							timezone: this.filters.timezone,
							cause: this.filters.cause
						};
						const options: AxiosRequestConfig = {
							method: "POST",
							headers: {
								Authorization: `Bearer ${localStorage.getItem("token")}`
							},
							data,
							url: `${store.getters.serverURL}/ingredient/scrapping/${stationId}`,
							cancelToken: this.axiosSource.token
						};
						const res: AxiosResponse<IServerRES<IIngredientScrappingInfo[]>> = await axios(options);
						if (res.data.err === ServerError.NO_ERROR) {
							localScrappingItems = localScrappingItems.concat(res.data.payload);
							totalLoaded += 1;
							this.progressPercentage = Math.floor((totalLoaded * 1.0 / this.filters.ingredients.length) * 100);
						}
					}

					this.scrappingItems = localScrappingItems;
				} catch (err) {
					console.error(err);
				}

				this.filterResults();
				// TBD
				// this.generateChartData();
			}

			this.tableLoading = false;
			return totalLoaded === this.filters.ingredients.length;
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		toggleSelectAllIngredients () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) {
				this.filters.ingredients = [];
			}	else {
				for (const item of this.ingredientSelectItems) {
					if (this.filters.ingredients.indexOf(item.value) === -1) {
						this.filters.ingredients.push(item.value);
					}
				}
			}
		},
		filterResults () {
			this.filteredScrappingItems = JSON.parse(JSON.stringify(this.scrappingItems));
			this.filteredScrappingItems.map((item) => {
				item.scrappingTime = item.scrappingDate;
			});
		},
		generateChartData () {
			const getMyColor = () => {
				const n = (Math.random() * 0xfffff * 1000000).toString(16);
				return `#${n.slice(0, 6)}`;
			};

			const newChardData: ChartData = {
				labels: [],
				datasets: []
			};

			const startDate = moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day");
			const endDate = moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day");

			switch (this.filters.grouping) {
				case ("Hourly"):
					for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "hour")) {
						if (newChardData.labels) {
							newChardData.labels.push(currDate.format("HH:mm"));
						}
					}
					break;

				default:
					for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "days")) {
						if (newChardData.labels) {
							newChardData.labels.push(currDate.format("DD/MM"));
						}
					}
			}

			const groupedItems = _.groupBy(this.filteredScrappingItems, "ingredientId");
			for (const key in groupedItems) {
				if (newChardData.datasets) {
					groupedItems[key].sort((a, b) => {
						return moment(a.scrappingDate).diff(moment(b.scrappingDate));
					});

					const data: number[] = [];
					switch (this.filters.grouping) {
						case ("Hourly"):
							for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "hour")) {
								let exists = false;
								for (const item of groupedItems[key]) {
									if (moment(item.scrappingDate).format("YYYYMMDDHH") === currDate.format("YYYYMMDDHH")) {
										data.push(item.qty);
										exists = true;
									}
								}

								if (!exists) {
									data.push(0);
								}
							}
							break;

						default:
							for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "days")) {
								let exists = false;
								for (const item of groupedItems[key]) {
									if (moment(item.scrappingDate).format("YYYYMMDD") === currDate.format("YYYYMMDD")) {
										data.push(item.qty);
										exists = true;
									}
								}

								if (!exists) {
									data.push(0);
								}
							}
					}

					const color = getMyColor();
					newChardData.datasets.push({
						label: groupedItems[key][0].ingredientName,
						data,
						borderColor: color,
						backgroundColor: color,
						fill: false
					});
				}
			}

			this.chartData.labels = newChardData.labels;
			this.chartData.datasets = newChardData.datasets;

			(this.$refs.myChart as InstanceType<typeof Chart>).updateChart();
		},
		async generateExcel (stationId: string | undefined) {
			if (await this.fetchData(stationId)) {
				const excelDoc: IIngredientScrappingInfoCSV = [];

				for (const item of this.filteredScrappingItems) {
					excelDoc.push({
						ingredientId: item.ingredientId,
						ingredientName: item.ingredientName,
						stationId: item.stationId,
						scrappingDate: moment(item.scrappingDate).format("DD/MM/YYYY"),
						scrappingTime: moment(item.scrappingTime).format("HH:mm"),
						qty: item.qty.toString(),
						unit: item.unit,
						cause: item.cause
					});
				}

				const ws = XLSX.utils.json_to_sheet(excelDoc);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Ingredient Scrapping");
				XLSX.writeFile(wb, "ingredient-scrapping.xlsx");
			}
		}
	}
});
