
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import OverviewTab from "@/components/dashboard/OverviewTab.vue";
import SalesTab from "@/components/dashboard/SalesTab.vue";
import CorrectionsReportTab from "@/components/dashboard/CorrectionsReportTab.vue";
import IngredientScrappingTab from "@/components/dashboard/IngredientScrappingTab.vue";
import ProductScrappingTab from "@/components/dashboard/ProductScrappingTab.vue";
import ProductionTab from "@/components/dashboard/ProductionTab.vue";
import IngredientReceptionTab from "@/components/dashboard/IngredientReceptionTab.vue";
import PredictionTab from "@/components/dashboard/PredictionTab.vue";
import LocalPredictionTab from "@/components/dashboard/LocalPredictionTab.vue";

import { IStation } from "@common/station";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

export default Vue.extend({
	name: "HAACP",
	components: {
		"overview-tab": OverviewTab,
		"sales-tab": SalesTab,
		"ingredient-scrapping-tab": IngredientScrappingTab,
		"product-scrapping-table": ProductScrappingTab,
		"production-tab": ProductionTab,
		"ingredient-reception-tab": IngredientReceptionTab,
		"prediction-tab": PredictionTab,
		"local-prediction-tab": LocalPredictionTab,
		"corrections-report-tab": CorrectionsReportTab,
	},
	data: () => {
		return {
			gasStation: {},
			gasStations: [] as IStation [],
			productsStats: [],
			scrappingStats: [],
			tab: null as number | null
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("Reports");
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.gasStation = this.gasStations[0];
				}
			}
			if (this.store.getters.operators.length === 0) {
				await this.store.dispatch.fetchOperators(this.gasStations);
			}
		} catch (err) {
			console.error(err);
		}
	}
});
