
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ILogin, ILoginRES, IUserToken, IVerifyRES } from "@common/user";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";

class MenuItem {
	constructor (
        public readonly title: string,
        public readonly link: string,
        public readonly icon: string,
	) {}
}

const SYSADMIN_MENU_ITEMS = [
	new MenuItem("Users", "/users", "person"),
	new MenuItem("Tablets", "/tablets", "tablet_mac"),
	new MenuItem("Projected Demand", "/demand", "stream"),
	new MenuItem("HAACP", "/haacp", "assessment"),
	new MenuItem("Standard Times", "/standard-times", "list"),
	new MenuItem("Traceability", "/traceability", "file_copy"),
	new MenuItem("Station Settings", "/stations", "local_gas_station"),
	new MenuItem("Settings", "/settings", "settings"),
];

const ADMIN_MENU_ITEMS = [
	new MenuItem("Reports", "/", "insights"),
	new MenuItem("Projected Demand", "/demand", "stream"),
	new MenuItem("HAACP", "/haacp", "assessment"),
	new MenuItem("Monitoring", "/monitoring", "monitor"),
	new MenuItem("Products", "/products", "$vuetify.icons.products"),
	new MenuItem("Ingredients", "/ingredients", "$vuetify.icons.ingredients"),
	new MenuItem("Standard Times", "/standard-times", "list"),
	new MenuItem("Traceability", "/traceability", "file_copy"),
	new MenuItem("Allergens", "/allergens", "$vuetify.icons.allergens"),
	new MenuItem("Tablets", "/tablets", "tablet_mac"),
	new MenuItem("Station Settings", "/stations", "local_gas_station"),
	new MenuItem("Settings", "/settings", "settings"),
];

const GASADMIN_MENU_ITEMS = [
	new MenuItem("Reports", "/", "insights"),
	new MenuItem("Operators", "/operators", "person"),
	new MenuItem("Projected Demand", "/demand", "stream"),
	new MenuItem("HAACP", "/haacp", "assessment"),
	new MenuItem("Monitoring", "/monitoring", "monitor"),
	new MenuItem("Station Settings", "/stations", "local_gas_station"),
	new MenuItem("Settings", "/settings", "settings"),
];

export default Vue.extend({
	name: "App",
	data: () => {
		const user: ILogin = {
			username: "",
			password: "",
		};
		return {
			renderPage: "",
			user,
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "error" as string,
			},
			hidePassword: true as boolean,
			navDrawer: true as boolean,
			submitForm: false as boolean,
			get token (): string {
				return localStorage.getItem("token") || "";
			},
			set token (value: string) {
				localStorage.setItem("token", value);
			},
			get role (): string {
				return localStorage.getItem("role") || "";
			},
			set role (value: string) {
				localStorage.setItem("role", value);
			},
		};
	},
	created: async function () {
		if (this.token === "") {
			this.renderPage = "login";
		} else {
			try {
				const data: IUserToken = {
					token: this.token,
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					data,
					url: `${store.getters.serverURL}/user/verify`,
				};
				const res: AxiosResponse<IServerRES<IVerifyRES>> =
                    await axios(options);
				switch (res.data.err) {
					case ServerError.NO_ERROR:
						this.role = res.data.payload.role;
						this.renderPage = "dashboard";
						break;
					case ServerError.UNAUTHORISED:
						this.logout();
						break;
				}
			} catch (err) {
				console.error(err);
				this.logout();
			}
		}
	},
	computed: {
		menuItems () {
			switch (this.role) {
				case "sys-admin":
					return SYSADMIN_MENU_ITEMS;
				case "admin":
					return ADMIN_MENU_ITEMS;
				case "gas-admin":
					return GASADMIN_MENU_ITEMS;
				default:
					return GASADMIN_MENU_ITEMS;
			}
		},
	},
	methods: {
		async submitInfos (): Promise<void> {
			this.submitForm = true;
			try {
				const data: ILogin = {
					username: this.user.username,
					password: this.user.password,
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					data,
					url: `${store.getters.serverURL}/user/login`,
				};
				const res: AxiosResponse<IServerRES<ILoginRES>> =
                    await axios(options);
				switch (res.data.err) {
					case ServerError.NO_ERROR:
						if (res.data.payload.token) {
							this.token = res.data.payload.token;
							this.role = res.data.payload.role;
							this.renderPage = "dashboard";
						}
						break;
					case ServerError.UNAUTHORISED:
						this.snackbar = {
							text: "Wrong credentials!",
							color: "error",
							show: true,
						};
						break;
				}
			} catch (err) {
				console.error(err);
			}

			(this.$refs.form as HTMLFormElement).reset();
			this.submitForm = false;
		},
		async logout (): Promise<void> {
			try {
				const data: IUserToken = {
					token: this.token,
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${this.token}`,
					},
					data,
					url: `${store.getters.serverURL}/user/logout`,
				};

				await axios(options);
			} catch (err) {
				console.error(err);
			}

			localStorage.removeItem("token");
			localStorage.removeItem("role");
			location.href = "/";
		},
	},
});
