
import Vue from "vue";
import {
	Chart,
	ChartData,
	LineController,
	LineElement,
	PointElement,
	CategoryScale,
	LinearScale,
	Title,
	Legend
} from "chart.js";

Chart.register(
	LineController,
	LineElement,
	PointElement,
	CategoryScale,
	LinearScale,
	Title,
	Legend
);

export default Vue.extend({
	name: "Chart",
	props: {
		chartId: String,
		chartDataProp: Object
	},
	data: () => {
		return {
			chart: null as Chart | null,
			chartData: {} as ChartData
		};
	},
	created: function () {
		this.chartData = this.chartDataProp;
	},
	mounted: function () {
		this.initChart();
	},
	methods: {
		initChart () {
			const target = document.getElementById(this.$props.chartId) as HTMLCanvasElement;
			if (target !== null) {
				const canvas = target.getContext("2d");
				if (canvas !== null) {
					this.chart = new Chart(canvas, {
						type: "line",
						data: this.chartData,
						options: {
							plugins: {
								title: {
									display: true,
									text: "Chart Title"
								},
								legend: {
									position: "top",
								}
							},
							layout: {
								padding: {
									left: 30,
									right: 30,
									top: 0,
									bottom: 0
								}
							}
						}
					});
				} else {
					console.error("failed getting context");
				}
			}
		},
		updateChart () {
			if (this.chart) {
				this.chart.update();
			}
		}
	}
});
