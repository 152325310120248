
import Vue from "vue";
import {
	Chart,
	ChartData,
	DoughnutController,
	PieController,
	ArcElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip
} from "chart.js";
import _ from "lodash";

Chart.register(
	PieController,
	DoughnutController,
	ArcElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip
);

export default Vue.extend({
	name: "TotalProduction",
	props: {
		chartId: String,
		chartDataProp: Object
	},
	data: () => {
		return {
			chart: null as Chart | null,
			chartData: {} as ChartData,
			chartTitle: "Total Production"
		};
	},
	created: function () {
		this.chartData = this.$props.chartDataProp;
	},
	mounted: function () {
		this.initChart();
	},
	methods: {
		initChart () {
			const target = document.getElementById(this.$props.chartId) as HTMLCanvasElement;
			if (target !== null) {
				const canvas = target.getContext("2d");
				if (canvas !== null) {
					this.chart = new Chart(canvas, {
						type: "doughnut",
						data: this.chartData,
						options: {
							plugins: {
								title: {
									display: true,
									text: this.chartTitle
								},
								legend: {
									display: false
								}
							},
							layout: {
								padding: {
									left: 30,
									right: 30,
									top: 0,
									bottom: 0
								}
							}
						}
					});

					target.onclick = (evt) => {
						if (this.chart) {
							const points = this.chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true);

							if (points.length) {
								const firstPoint = points[0];
								if (this.store.getters.products[firstPoint.index]) {
									this.$emit("clicked", this.store.getters.products[firstPoint.index].id);
								}
							}
						}
					};
				} else {
					console.error("failed getting context");
				}
			}
		},
		updateChart () {
			let total = -1;
			if (this.chartData) {
				total = _.sum(this.chartData.datasets[0].data);
			}

			if (total > -1) {
				this.chartTitle = `Total Production: ${total}`;
			} else {
				this.chartTitle = "Total Production Pie Chart";
			}

			if (this.chart) {
				if (this.chart.options.plugins && this.chart.options.plugins?.title) {
					this.chart.options.plugins.title.text = this.chartTitle;
				}
				this.chart.update();
			}
		}
	}
});
