
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as XLSX from "xlsx";

import TotalPredictionsChart from "@/components/dashboard/overview-charts/TotalPredictions.vue";
import TotalProductionChart from "@/components/dashboard/overview-charts/TotalProduction.vue";
import TotalSalesChart from "@/components/dashboard/overview-charts/TotalSales.vue";
import TotalScrappingChart from "@/components/dashboard/overview-charts/TotalScrapping.vue";
import ProductDailyOverviewChart from "@/components/dashboard/overview-charts/ProductDailyOverview.vue";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import {
	IProductDailyOverviewCSV,
	IProductDailyOverviewFilter,
	IProductDailyOverviews,
	IProductDailyOverview,
	IProductProductionFilter,
	IProductsStatCumulated
} from "@common/product";
import IframeDialog from "@/components/common/IframeDialog.vue";
import { ChartData } from "chart.js";
import { IPredictionsCumulated, IPredictionsFilter } from "@common/predictions";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "ProductionTable",
	props: {
		stationId: String,
		stationName: String
	},
	components: {
		"iframe-dialog": IframeDialog,
		"total-predictions-chart": TotalPredictionsChart,
		"total-production-chart": TotalProductionChart,
		"total-sales-chart": TotalSalesChart,
		"total-scrapping-chart": TotalScrappingChart,
		"product-daily-overview-chart": ProductDailyOverviewChart
	},
	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			search: "" as string,
			tableLoading: false as boolean,
			progressPercentage: 0 as number,
			showChart: false as boolean,
			headers: [
				new TableHeader("Date", "start", true, "date"),
				new TableHeader("Product", "start", true, "product"),
				new TableHeader("Prediction", "start", true, "predictionQty"),
				new TableHeader("Production", "start", true, "productionQty"),
				new TableHeader("Sales", "start", true, "salesQty"),
				new TableHeader("Scrapping", "start", true, "scrappingQty"),
				new TableHeader("Error percentage", "start", true, "errorPercentage")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			autoCompleteChipsLimit: 10,
			productDailyOverviewItems: [] as IProductDailyOverviews,
			filters: {
				products: [] as string[],
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
				timezone: moment().format("Z")
			},
			menus: {
				dateRange: false
			},
			selectedRange: 3,
			dummyTableData: [
				{
					date: "20/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 16,
					production: 20,
					sales: 18,
					scrapping: 2
				},
				{
					date: "21/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 29,
					production: 15,
					sales: 15,
					scrapping: 0
				},
				{
					date: "22/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 48,
					production: 48,
					sales: 40,
					scrapping: 8
				},
				{
					date: "23/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 13,
					production: 13,
					sales: 10,
					scrapping: 3
				},
				{
					date: "24/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 22,
					production: 20,
					sales: 18,
					scrapping: 2
				},
				{
					date: "25/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 14,
					production: 14,
					sales: 18,
					scrapping: 0
				},
				{
					date: "26/09/21",
					product: "(403778) SW CU SUNCA",
					prediction: 8,
					production: 8,
					sales: 7,
					scrapping: 1
				}
			],
			chartData: {
				totalPredictions: {
					labels: [],
					datasets: [
						{
							label: "Predictions",
							data: [],
							backgroundColor: [],
							hoverOffset: 4
						}
					]
				} as ChartData,
				totalProduction: {
					labels: [],
					datasets: [
						{
							label: "Production",
							data: [],
							backgroundColor: [],
							hoverOffset: 4
						}
					]
				} as ChartData,
				totalSales: {
					labels: [],
					datasets: [
						{
							label: "Sales",
							data: [],
							backgroundColor: [],
							hoverOffset: 4
						}
					]
				} as ChartData,
				totalScrapping: {
					labels: [],
					datasets: [
						{
							label: "Scrapping",
							data: [],
							backgroundColor: [],
							hoverOffset: 4
						}
					]
				} as ChartData,
				dailyOverview: {
					labels: [],
					datasets: [
						{
							label: "Predictions",
							data: [] as number[],
							borderColor: "#9AD0F5",
							backgroundColor: "#9AD0F5",
							fill: false
						},
						{
							label: "Production",
							data: [] as number[],
							borderColor: "#FFE6AA",
							backgroundColor: "#FFE6AA",
							fill: false
						},
						{
							label: "Sales",
							data: [] as number[],
							borderColor: "#A5DFDF",
							backgroundColor: "#A5DFDF",
							fill: false
						},
						{
							label: "Scrapping",
							data: [] as number[],
							borderColor: "#FFB1C1",
							backgroundColor: "#FFB1C1",
							fill: false
						}
					]
				} as ChartData
			}
		};
	},
	created: async function () {
		// Set pdfmake fonts
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}

		this.filters.products = [this.productSelectItems[0].value];

		await this.fetchData(this.$props.stationId);
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		productTitle () {
			return (productId: string) => {
				const product = this.store.getters.products.find((item) => {
					return item.id === productId;
				});

				return `(${productId}) ${product?.title}`;
			};
		},
		productSelectItems () {
			const productSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const product of this.store.getters.products) {
				const item = {
					text: `(${product.id}) ${product.title}`,
					value: product.id
				};

				if (_.findIndex(productSelectItems, item) === -1) {
					productSelectItems.push(item);
				}
			}

			return productSelectItems;
		},
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}

				return dateRange;
			}

			return "";
		},
		icon () {
			if (this.filters.products.length === this.productSelectItems.length) return "mdi-close-box";
			if (this.filters.products.length !== this.productSelectItems.length && this.filters.products.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
	},
	watch: {
		stationId: async function (newStationId, oldStationId) {
			// We already fetch the data on created hook
			if (oldStationId !== undefined) {
				await this.fetchData(newStationId);
			}
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Request canceled by user.");
				this.axiosSource = axios.CancelToken.source();
			}
		}
	},
	methods: {
		checkIfHighlight (item: IProductDailyOverview) {
			if (item.productionQty < item.predictionQty && item.scrappingQty === 0) {
				return "table__row__highlight";
			}

			if (item.salesQty > item.productionQty) {
				return "table__row__highlight_yellow";
			}

			return "";
		},
		updateFiltersProduct (product: string) {
			this.filters.products = [product];
			this.fetchData(this.$props.stationId);
		},
		async fetchData (stationId: string | undefined) {
			this.tableLoading = true;
			this.progressPercentage = 0;
			let totalLoaded = 0;

			if (stationId) {
				try {
					let localProductDailyOverviewItems: IProductDailyOverviews = [];

					for (const productId of this.filters.products) {
						const data: IProductDailyOverviewFilter = {
							date: {
								start: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
								end: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
							},
							productId,
							stationId,
							timezone: this.filters.timezone
						};
						const options: AxiosRequestConfig = {
							method: "POST",
							headers: {
								Authorization: `Bearer ${localStorage.getItem("token")}`
							},
							data,
							url: `${store.getters.serverURL}/product/stats/daily-overview`,
							cancelToken: this.axiosSource.token
						};
						const res: AxiosResponse<IServerRES<IProductDailyOverviews>> = await axios(options);
						if (res.data.err === ServerError.NO_ERROR) {
							localProductDailyOverviewItems = localProductDailyOverviewItems.concat(res.data.payload);
							totalLoaded += 1;
							this.progressPercentage = Math.floor((totalLoaded * 1.0 / this.filters.products.length) * 100);
						}
					}

					this.productDailyOverviewItems = localProductDailyOverviewItems;
					this.generateDailyOverviewChartData(this.productDailyOverviewItems);

					await this.fetchPredictionsCumulated(stationId);
					await this.fetchProductionCumulated(stationId);
					await this.fetchSalesCumulated(stationId);
					await this.fetchScrappingCumulated(stationId);
				} catch (err) {
					console.error(err);
				}
			}

			this.tableLoading = false;
			return totalLoaded === this.filters.products.length;
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		async fetchPredictionsCumulated (stationId: string) {
			try {
				const data: IPredictionsFilter = {
					date: {
						start: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
						end: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
					},
					stationId
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/prediction/cumulated`,
					cancelToken: this.axiosSource.token
				};
				const res: AxiosResponse<IServerRES<IPredictionsCumulated>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.generatePredictionsChartData(res.data.payload);
				}
			} catch (err) {
				console.error(err);
			}
		},
		generatePredictionsChartData (predictions: IPredictionsCumulated) {
			const chartData = this.chartData.totalPredictions;

			chartData.labels = [];
			chartData.datasets[0].data = [];
			chartData.datasets[0].backgroundColor = [];

			for (const product of this.store.getters.products) {
				if (chartData && chartData.labels) {
					chartData.labels.push(`(${product.id}) ${product.title}`);

					const productPrediction = predictions.find((prediction) => {
						return prediction.productId === product.id;
					});

					if (productPrediction) {
						chartData.datasets[0].data.push(productPrediction.qty);
					} else {
						chartData.datasets[0].data.push(0);
					}

					if (chartData.datasets[0].backgroundColor) {
						// chartjs missing string[] type for background color even if it's present in documentation
						(chartData.datasets[0].backgroundColor as string[]).push(store.state.dashboard.colorPresets[product.id]);
					}
				}
			}

			(this.$refs.totalPredictionsChart as InstanceType<typeof TotalPredictionsChart>).updateChart();
		},
		async fetchProductionCumulated (stationId: string) {
			try {
				const data: IProductProductionFilter = {
					date: {
						from: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
						until: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/production/${stationId}/cumulated`,
					cancelToken: this.axiosSource.token
				};
				const res: AxiosResponse<IServerRES<IProductsStatCumulated>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.generateProductStatChartData(res.data.payload, this.chartData.totalProduction);
					(this.$refs.totalProductionChart as InstanceType<typeof TotalProductionChart>).updateChart();
				} else {
					console.error(res.data);
				}
			} catch (err) {
				console.error(err);
			}
		},
		async fetchSalesCumulated (stationId: string) {
			try {
				const data: IProductProductionFilter = {
					date: {
						from: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
						until: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/stats/sales/${stationId}/cumulated`,
					cancelToken: this.axiosSource.token
				};
				const res: AxiosResponse<IServerRES<IProductsStatCumulated>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.generateProductStatChartData(res.data.payload, this.chartData.totalSales);
					(this.$refs.totalSalesChart as InstanceType<typeof TotalSalesChart>).updateChart();
				} else {
					console.error(res.data);
				}
			} catch (err) {
				console.error(err);
			}
		},
		async fetchScrappingCumulated (stationId: string) {
			try {
				const data: IProductProductionFilter = {
					date: {
						from: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
						until: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/stats/scrapping/${stationId}/cumulated`,
					cancelToken: this.axiosSource.token
				};
				const res: AxiosResponse<IServerRES<IProductsStatCumulated>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.generateProductStatChartData(res.data.payload, this.chartData.totalScrapping);
					(this.$refs.totalScrappingChart as InstanceType<typeof TotalScrappingChart>).updateChart();
				} else {
					console.error(res.data);
				}
			} catch (err) {
				console.error(err);
			}
		},
		generateProductStatChartData (productStat: IProductsStatCumulated, chartData: ChartData) {
			chartData.labels = [];
			chartData.datasets[0].data = [];
			chartData.datasets[0].backgroundColor = [];

			for (const product of this.store.getters.products) {
				if (chartData && chartData.labels) {
					chartData.labels.push(`(${product.id}) ${product.title}`);

					const productProduction = productStat.find((productStat) => {
						return productStat.productId === product.id;
					});

					if (productProduction) {
						chartData.datasets[0].data.push(productProduction.qty);
					} else {
						chartData.datasets[0].data.push(0);
					}

					if (chartData.datasets[0].backgroundColor) {
						// chartjs missing string[] type for background color even if it's present in documentation
						(chartData.datasets[0].backgroundColor as string[]).push(store.state.dashboard.colorPresets[product.id]);
					}
				}
			}
		},
		generateDailyOverviewChartData (productDailyOverviewItems: IProductDailyOverviews) {
			const dailyOverview = this.chartData.dailyOverview;

			dailyOverview.labels = [];
			// prediction data
			dailyOverview.datasets[0].data = [];
			// production data
			dailyOverview.datasets[1].data = [];
			// sales data
			dailyOverview.datasets[2].data = [];
			// scrapping data
			dailyOverview.datasets[3].data = [];

			for (const item of productDailyOverviewItems) {
				const index = dailyOverview.labels.indexOf(moment(item.date).format("DD/MM/YYYY"));
				if (index === -1) {
					dailyOverview.labels.push(moment(item.date).format("DD/MM/YYYY"));
					dailyOverview.datasets[0].data.push(item.predictionQty);
					dailyOverview.datasets[1].data.push(item.productionQty);
					dailyOverview.datasets[2].data.push(item.salesQty);
					dailyOverview.datasets[3].data.push(item.scrappingQty);
				} else {
					dailyOverview.datasets[0].data[index] = (dailyOverview.datasets[0].data[index] as number) + item.predictionQty;
					dailyOverview.datasets[1].data[index] = (dailyOverview.datasets[1].data[index] as number) + item.productionQty;
					dailyOverview.datasets[2].data[index] = (dailyOverview.datasets[2].data[index] as number) + item.salesQty;
					dailyOverview.datasets[3].data[index] = (dailyOverview.datasets[3].data[index] as number) + item.scrappingQty;
				}
			}

			(this.$refs.productDailyOverviewChart as InstanceType<typeof ProductDailyOverviewChart>).updateChart();
		},
		async generateExcel (stationId: string | undefined) {
			if (await this.fetchData(stationId)) {
				const excelDoc: IProductDailyOverviewCSV = [];

				for (const item of this.productDailyOverviewItems) {
					const product = this.store.getters.products.find((prod) => {
						return prod.id === item.productId;
					});

					excelDoc.push({
						stationId: item.stationId,
						productId: item.productId,
						productName: product?.title || "",
						productionQty: item.productionQty,
						predictionQty: item.predictionQty,
						salesQty: item.salesQty,
						scrappingQty: item.scrappingQty,
						date: moment(item.date).format("DD/MM/YYYY"),
						errorPercentage: this.predictionErrorPercentage(item.predictionQty, item.salesQty)
					});
				}

				const ws = XLSX.utils.json_to_sheet(excelDoc);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Product Daily Overview");
				XLSX.writeFile(wb, "product-daily-overview.xlsx");
			}
		},
		predictionErrorPercentage (predictionQty: number, salesQty: number): number {
			if (salesQty === 0) {
				if (predictionQty === 0) {
					return 0;
				}
				return 100;
			}
			return Math.round(Math.abs(salesQty - predictionQty) / salesQty * 100);
		},
		toggleSelectAllProducts () {
			if (this.filters.products.length === this.productSelectItems.length) {
				this.filters.products = [];
			}	else {
				for (const item of this.productSelectItems) {
					if (this.filters.products.indexOf(item.value) === -1) {
						this.filters.products.push(item.value);
					}
				}
			}
		},
	}
});
