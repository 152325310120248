
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as XLSX from "xlsx";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import {
	IProductProductionIngredientsInfoFilter,
	IProductProductionStats,
	IProductProductionStatsCSV,
	IProductProductionStatsFilter,
	IProductStatSalesInfo,
	IProductStatScrappingInfo
} from "@common/product";
import IframeDialog from "@/components/common/IframeDialog.vue";
import { IIngredientsChunkInfo } from "@common/ingredientChunk";
import { IIngredientsOpenInfo } from "@common/ingredientOpen";
import { IIngredientsFryingInfo } from "@common/ingredientFrying";
import { IProductTraceabilityReport } from "@common/reports";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { IIngredientsBakingInfo, IIngredientsDefrostInfo } from "@common/bread";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

interface ProductProductionStats extends IProductProductionStats {
	productionTime?: Date;
}

export default Vue.extend({
	name: "ProductionTab",
	props: {
		stationId: String,
		stationName: String
	},
	components: {
		"iframe-dialog": IframeDialog
	},
	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			search: "" as string,
			tableLoading: false as boolean,
			progressPercentage: 0 as number,
			showChart: false as boolean,
			headers: [
				new TableHeader("Barcode", "start", true, "barcode"),
				new TableHeader("Product ID", "start", true, "productId"),
				new TableHeader("Product Name", "start", true, "productName"),
				new TableHeader("Production Date", "start", true, "dateProduction"),
				new TableHeader("Production Time", "start", true, "productionTime"),
				new TableHeader("Operator Name", "start", true, "operatorName"),
				new TableHeader("Quantity", "start", true, "qty"),
				new TableHeader("Actions", "start", false, "actions")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			autoCompleteChipsLimit: 10,
			productionStatsItems: [] as IProductProductionStats[],
			filteredProductionStatsItems: [] as ProductProductionStats[],
			filters: {
				products: [] as string[],
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				]
			},
			menus: {
				dateRange: false
			},
			selectedRange: 3
		};
	},
	created: async function () {
		// Set pdfmake fonts
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}
		this.filters.products = [this.productSelectItems[0].value];
		await this.fetchData(this.$props.stationId);
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		productSelectItems () {
			const productSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const product of this.store.getters.products) {
				const item = {
					text: `(${product.id}) ${product.title}`,
					value: product.id
				};

				if (_.findIndex(productSelectItems, item) === -1) {
					productSelectItems.push(item);
				}
			}

			return productSelectItems;
		},
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}

				return dateRange;
			}

			return "";
		},
		icon () {
			if (this.filters.products.length === this.productSelectItems.length) return "mdi-close-box";
			if (this.filters.products.length !== this.productSelectItems.length && this.filters.products.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		totalSales () {
			return _.sumBy(this.filteredProductionStatsItems, "qty");
		}
	},
	watch: {
		stationId: async function (newStationId) {
			await this.fetchData(newStationId);
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Operation canceled by the user.");
				this.axiosSource = axios.CancelToken.source();
			}
		}
	},
	methods: {
		async fetchData (stationId: string | undefined) {
			this.tableLoading = true;
			this.progressPercentage = 0;
			let totalLoaded = 0;

			if (stationId) {
				try {
					let localProductionStatsItems: IProductProductionStats[] = [];

					for (const product of this.filters.products) {
						const data: IProductProductionStatsFilter = {
							date: {
								from: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
								until: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
							},
							product
						};
						const options: AxiosRequestConfig = {
							method: "POST",
							headers: {
								Authorization: `Bearer ${localStorage.getItem("token")}`
							},
							data,
							url: `${store.getters.serverURL}/product/production/stats/${stationId}`,
							cancelToken: this.axiosSource.token
						};
						const res: AxiosResponse<IServerRES<IProductProductionStats[]>> = await axios(options);
						if (res.data.err === ServerError.NO_ERROR) {
							localProductionStatsItems = localProductionStatsItems.concat(res.data.payload);
							totalLoaded += 1;
							this.progressPercentage = Math.floor((totalLoaded * 1.0 / this.filters.products.length) * 100);
						}
					}

					this.productionStatsItems = localProductionStatsItems;
				} catch (err) {
					console.error(err);
				}

				this.filterResults();
			}

			this.tableLoading = false;
			return totalLoaded === this.filters.products.length;
		},
		async fetchSalesInfo (barcode: string, stationId: string): Promise<IProductStatSalesInfo[]> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/stats/sales/${stationId}/barcode`,
				};
				const res: AxiosResponse<IServerRES<IProductStatSalesInfo[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchScrappingInfo (barcode: string, stationId: string): Promise<IProductStatScrappingInfo[]> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/stats/scrapping/${stationId}/barcode`,
				};
				const res: AxiosResponse<IServerRES<IProductStatScrappingInfo[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchIngredientsChunkInfo (barcode: string, stationId: string): Promise<IIngredientsChunkInfo> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/production/stats/ingredients/chunk/info/${stationId}`,
				};
				const res: AxiosResponse<IServerRES<IIngredientsChunkInfo>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchIngredientsOpenInfo (barcode: string, stationId: string): Promise<IIngredientsOpenInfo> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/production/stats/ingredients/open/info/${stationId}`,
				};
				const res: AxiosResponse<IServerRES<IIngredientsOpenInfo>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchIngredientsFryingInfo (barcode: string, stationId: string): Promise<IIngredientsFryingInfo> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/production/stats/ingredients/frying/info/${stationId}`,
				};
				const res: AxiosResponse<IServerRES<IIngredientsFryingInfo>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchIngredientsBakingInfo (barcode: string, stationId: string): Promise<IIngredientsBakingInfo> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/production/stats/ingredients/baking/info/${stationId}`,
				};
				const res: AxiosResponse<IServerRES<IIngredientsBakingInfo>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchIngredientsDefrostInfo (barcode: string, stationId: string): Promise<IIngredientsDefrostInfo> {
			try {
				const data: IProductProductionIngredientsInfoFilter = {
					barcode
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${store.getters.serverURL}/product/production/stats/ingredients/defrost/info/${stationId}`,
				};
				const res: AxiosResponse<IServerRES<IIngredientsDefrostInfo>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		filterResults () {
			this.filteredProductionStatsItems = JSON.parse(JSON.stringify(this.productionStatsItems));
			this.filteredProductionStatsItems.map((item) => {
				item.productionTime = item.dateProduction;
			});
		},
		toggleSelectAllProducts () {
			if (this.filters.products.length === this.productSelectItems.length) {
				this.filters.products = [];
			}	else {
				for (const item of this.productSelectItems) {
					if (this.filters.products.indexOf(item.value) === -1) {
						this.filters.products.push(item.value);
					}
				}
			}
		},
		async viewProductTraceabilityReportPDF (barcode: string) {
			const productionStatItem = this.productionStatsItems.find((item) => item.barcode === barcode);
			if (productionStatItem) {
				const report: IProductTraceabilityReport = {
					productId: productionStatItem.productId,
					productName: productionStatItem.productName || "",
					qty: productionStatItem.qty,
					productionDate: productionStatItem.dateProduction,
					expirationDate: productionStatItem.dateExpiration,
					batch: moment(productionStatItem.dateProduction).format("DD / MM"),
					barcode: productionStatItem.barcode,
					operatorName: productionStatItem?.operatorName || "",
					salesInfo: await this.fetchSalesInfo(productionStatItem.barcode, productionStatItem.stationId),
					scrappingInfo: await this.fetchScrappingInfo(productionStatItem.barcode, productionStatItem.stationId),
					ingredientsChunkInfo: await this.fetchIngredientsChunkInfo(productionStatItem.barcode, productionStatItem.stationId),
					ingredientsOpenInfo: await this.fetchIngredientsOpenInfo(productionStatItem.barcode, productionStatItem.stationId),
					ingredientsFryingInfo: await this.fetchIngredientsFryingInfo(productionStatItem.barcode, productionStatItem.stationId),
					ingredientsBakingInfo: await this.fetchIngredientsBakingInfo(productionStatItem.barcode, productionStatItem.stationId),
					ingredientsDefrostInfo: await this.fetchIngredientsDefrostInfo(productionStatItem.barcode, productionStatItem.stationId)
				};

				const docContent = [];
				docContent.push({
					text: `STAȚIA: ${this.$props.stationName.replace(`(${this.$props.stationId})`, "").trim()}`,
					fontSize: 10
				});
				docContent.push({
					text: "V1_2021",
					alignment: "right",
					fontSize: 10
				});
				docContent.push({
					text: `Data: ${moment().format("DD/MM/yyyy")}`,
					fontSize: 10
				});
				docContent.push({
					text: "Raport Trasabilitate",
					alignment: "center",
					bold: true,
					fontSize: 15,
					margin: [0, 20, 0, 0]
				});
				docContent.push({
					text: `Produs șarjă: (${report.productId}) ${report.productName}`,
					fontSize: 11,
					margin: [0, 20, 0, 0]
				});
				docContent.push({
					text: `Cantitate șarjă: ${report.qty}`,
					fontSize: 11,
					margin: [0, 5, 0, 0]
				});
				docContent.push({
					text: `Dată producție șarjă: ${moment(report.productionDate).format("HH:mm:ss DD/MM/YYYY")}`,
					fontSize: 11,
					margin: [0, 5, 0, 0]
				});
				docContent.push({
					text: `Dată expirare șarjă: ${moment(report.expirationDate).format("HH:mm:ss DD/MM/YYYY")}`,
					fontSize: 11,
					margin: [0, 5, 0, 0]
				});
				docContent.push({
					text: `Lot șarjă: ${report.batch}`,
					fontSize: 11,
					margin: [0, 5, 0, 0]
				});
				docContent.push({
					text: `Cod de bare șarjă: ${report.barcode}`,
					fontSize: 11,
					margin: [0, 5, 0, 0]
				});
				docContent.push({
					text: `Responsabil șarjă: ${report.operatorName}`,
					fontSize: 11,
					margin: [0, 5, 0, 0]
				});
				if (report.ingredientsChunkInfo.length > 0) {
					docContent.push({
						text: "Informații ingrediente feliate",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateIngredientsChunkInfoTable(report.ingredientsChunkInfo),
						fontSize: 11
					});
				}
				if (report.ingredientsOpenInfo.length > 0) {
					docContent.push({
						text: "Informații ingrediente",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateIngredientsOpenInfoTable(report.ingredientsOpenInfo),
						fontSize: 11
					});
				}
				if (report.ingredientsFryingInfo.length > 0) {
					docContent.push({
						text: "Informații ingrediente prăjite",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateIngredientsFryingInfoTable(report.ingredientsFryingInfo),
						fontSize: 11
					});
				}
				if (report.ingredientsBakingInfo.length > 0) {
					docContent.push({
						text: "Informații ingrediente coapte",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateIngredientsBakingInfoTable(report.ingredientsBakingInfo),
						fontSize: 11
					});
				}
				if (report.ingredientsDefrostInfo.length > 0) {
					docContent.push({
						text: "Informații ingrediente decongelate",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateIngredientsDefrostInfoTable(report.ingredientsDefrostInfo),
						fontSize: 11
					});
				}
				if (report.salesInfo.length > 0) {
					docContent.push({
						text: "Informații vânzări șarjă",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateSalesInfoTable(report.salesInfo),
						fontSize: 11
					});
				}
				if (report.scrappingInfo.length > 0) {
					docContent.push({
						text: "Informații scrapping șarjă",
						fontSize: 11,
						margin: [0, 20, 0, 0]
					});
					docContent.push({
						margin: [0, 5, 0, 0],
						table: this.generateScrappingInfoTable(report.scrappingInfo),
						fontSize: 11
					});
				}
				docContent.push({
					text: "Se verifica conditiile de temperatura si igiena in care s-a receptionat produsul, daca corespund sau nu",
					fontSize: 11,
					margin: [0, 30, 0, 0]
				});
				docContent.push({
					text: "Se verifica graficele de monitorizare a temperaturii pentru congelatorul in care a fost pastrat lotul respectiv pana la preparare",
					fontSize: 11,
					margin: [0, 50, 0, 0]
				});
				docContent.push({
					text: "Au existat abateri de la limitele de temperatura?",
					fontSize: 11,
					margin: [0, 50, 0, 0]
				});
				docContent.push({
					text: "Daca da, ce masuri au fost intreprinse?",
					fontSize: 11,
					margin: [0, 50, 0, 0]
				});
				docContent.push({
					text: "Alte observații",
					fontSize: 11,
					margin: [0, 50, 0, 0]
				});

				const docDefinition: TDocumentDefinitions = {
					pageOrientation: "landscape",
					content: docContent as TDocumentDefinitions["content"],
					footer: function (currentPage: number, pageCount: number) {
						return {
							text: `${currentPage.toString()}/${pageCount.toString()}`,
							alignment: "center",
							margin: [0, 10, 0, 0]
						};
					}
				};

				const pdfDocGenerator = pdfMake.createPdf(docDefinition);
				pdfDocGenerator.getDataUrl(async (dataUrl) => {
					await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Product Traceability Report", dataUrl);
				});
			}
		},
		generateIngredientsChunkInfoTable (ingredientsChunkInfo: IIngredientsChunkInfo) {
			const table = {
				headerRows: 1,
				widths: ["auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Dată feliere",
							alignment: "center"

						},
						{
							text: "Dată expirare",
							alignment: "center"
						},
						{
							text: "Responsabil feliere",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of ingredientsChunkInfo) {
				table.body.push([
					{
						text: `(${entry.ingredientId}) ${entry.ingredientName}`,
						alignment: "center"
					},
					{
						text: moment(entry.dateSlice).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: moment(entry.dateExpire).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateIngredientsOpenInfoTable (ingredientsOpenInfo: IIngredientsOpenInfo) {
			const table = {
				headerRows: 1,
				widths: ["auto", "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Lot produs",
							alignment: "center"
						},
						{
							text: "Dată expirare",
							alignment: "center"
						},
						{
							text: "Dată deschidere",
							alignment: "center"

						},
						{
							text: "Dată expirare după deschidere",
							alignment: "center"
						},
						{
							text: "Responsabil deschidere",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of ingredientsOpenInfo) {
				table.body.push([
					{
						text: `(${entry.ingredientId}) ${entry.ingredientName}`,
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: moment(entry.dateExpire).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: moment(entry.dateOpen).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: moment(entry.dateExpireAfterOpen).add(6, "hours").format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateIngredientsFryingInfoTable (ingredientsFryingInfo: IIngredientsFryingInfo) {
			const table = {
				headerRows: 1,
				widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Lot produs",
							alignment: "center"
						},
						{
							text: "Dată prăjire",
							alignment: "center"

						},
						{
							text: "Temperatura de prelucrare termică (TºC)",
							alignment: "center"
						},
						{
							text: "Durata de menţinere a temperaturii la prelucrare termică (min.)",
							alignment: "center"
						},
						{
							text: "Temperatura produsului dupa prelucrarea termica (TºC)",
							alignment: "center"
						},
						{
							text: "Responsabil prăjire",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of ingredientsFryingInfo) {
				table.body.push([
					{
						text: `(${entry.ingredientId}) ${entry.ingredientName}`,
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: moment(entry.date).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: `${entry.tempMin} - ${entry.tempMax}`,
						alignment: "center"
					},
					{
						text: entry.processingTime.toString(),
						alignment: "center"
					},
					{
						text: entry.temperatureAfterProcessing.toString(),
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateIngredientsBakingInfoTable (ingredientsBakingInfo: IIngredientsBakingInfo) {
			const table = {
				headerRows: 1,
				widths: [150, "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Cantitate produsă",
							alignment: "center"

						},
						{
							text: "Data producției (ora scoaterii din cuptor)",
							alignment: "center"
						},
						{
							text: "Nr. Lot furnizor",
							alignment: "center"
						},
						{
							text: "Termen de valabilitate al produsului dupa coacere",
							alignment: "center"
						},
						{
							text: "Responsabil producție",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of ingredientsBakingInfo) {
				table.body.push([
					{
						text: `(${entry.ingredientId}) ${entry.ingredientName}`,
						alignment: "center"
					},
					{
						text: entry.qty.toString(),
						alignment: "center"
					},
					{
						text: moment(entry.date).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: entry.valabilityAfterBaking,
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateIngredientsDefrostInfoTable (ingredientsDefrostInfo: IIngredientsDefrostInfo) {
			const table = {
				headerRows: 1,
				widths: [100, "auto", "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Nr. bucăți scoase la decongelat",
							alignment: "center"

						},
						{
							text: "Data scoaterii la decongelat",
							alignment: "center"
						},
						{
							text: "Data decongelării",
							alignment: "center"
						},
						{
							text: " Lot furnizor",
							alignment: "center"
						},
						{
							text: "Termen valabilitate din momentul decongelării",
							alignment: "center"
						},
						{
							text: "Responsabil producție",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of ingredientsDefrostInfo) {
				table.body.push([
					{
						text: `(${entry.ingredientId}) ${entry.ingredientName}`,
						alignment: "center"
					},
					{
						text: entry.qty.toString(),
						alignment: "center"
					},
					{
						text: moment(entry.dateStart).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: moment(entry.dateEnd).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: entry.valabilityAfterDefrost,
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateSalesInfoTable (salesInfo: IProductStatSalesInfo[]) {
			const table = {
				headerRows: 1,
				widths: ["auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Dată vânzare",
							alignment: "center"

						},
						{
							text: "Tipul vânzării",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of salesInfo) {
				table.body.push([
					{
						text: `(${entry.soldId}) ${entry.productName}`,
						alignment: "center"
					},
					{
						text: moment(entry.soldDate).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: entry.soldType,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateScrappingInfoTable (scrappingInfo: IProductStatScrappingInfo[]) {
			const table = {
				headerRows: 1,
				widths: ["auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Dată scrapping",
							alignment: "center"

						},
						{
							text: "Motiv scrapping",
							alignment: "center"
						},
						{
							text: "Responsabil scrapping",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of scrappingInfo) {
				table.body.push([
					{
						text: `(${entry.productId}) ${entry.productName}`,
						alignment: "center"
					},
					{
						text: moment(entry.scrappingDate).format("HH:mm:ss DD/MM/YYYY"),
						alignment: "center"
					},
					{
						text: entry.cause || "",
						alignment: "center"
					},
					{
						text: entry.scrappingOperatorName || "",
						alignment: "center"
					}
				]);
			}

			return table;
		},
		async generateExcel (stationId: string | undefined) {
			if (await this.fetchData(stationId)) {
				const excelDoc: IProductProductionStatsCSV = [];

				for (const item of this.filteredProductionStatsItems) {
					excelDoc.push({
						barcode: item.barcode,
						productId: item.productId,
						productName: item.productName || "Unknown",
						productionDate: moment(item.dateProduction).format("DD/MM/YYYY"),
						productionTime: moment(item.productionTime).format("HH:mm"),
						operatorName: item.operatorName || "Unknown",
						stationId: item.stationId,
						qty: item.qty.toString()
					});
				}

				const ws = XLSX.utils.json_to_sheet(excelDoc);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Production");
				XLSX.writeFile(wb, "production.xlsx");
			}
		}
	}
});
