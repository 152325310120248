
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import { ChartData } from "chart.js";
import * as XLSX from "xlsx";

import Chart from "@/components/common/Chart.vue";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IProductStatSalesInfo, IProductStatSalesFilter, IProductStatSalesInfoCSV } from "@common/product";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

interface ProductStatInfoSales extends IProductStatSalesInfo {
	productionTime?: Date;
	soldTime?: Date;
}

export default Vue.extend({
	name: "SalesTab",
	props: {
		stationId: String
	},
	components: {
		chart: Chart
	},
	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			search: "" as string,
			tableLoading: false as boolean,
			progressPercentage: 0 as number,
			tableSortBy: "" as string,
			showChart: false as boolean,
			headers: [
				new TableHeader("Product Name", "start", true, "productName"),
				new TableHeader("Production Date", "start", true, "productionDate"),
				new TableHeader("Production Time", "start", true, "productionTime"),
				new TableHeader("Sold Date", "start", true, "soldDate"),
				new TableHeader("Sold Time", "start", true, "soldTime"),
				new TableHeader("Quantity", "start", true, "qty"),
				new TableHeader("Sold Type", "start", true, "soldType")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			autoCompleteChipsLimit: 10,
			salesItems: [] as IProductStatSalesInfo[],
			filteredSalesItems: [] as ProductStatInfoSales[],
			filters: {
				grouping: store.state.dashboard.filters.grouping[0],
				soldType: store.state.dashboard.filters.soldType[0],
				products: [] as string[],
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
				timeRange: {
					start: store.state.dashboard.timePresets.start[0],
					end: store.state.dashboard.timePresets.end[store.state.dashboard.timePresets.end.length - 1]
				},
				dateFilterBy: store.state.dashboard.filters.dateFilterBy.sales[0],
				timezone: moment().format("Z")
			},
			menus: {
				dateRange: false,
				timeRange: false
			},
			selectedRange: 3,
			chartData: {
				labels: [],
				datasets: []
			} as ChartData
		};
	},
	created: async function () {
		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}
		this.filters.products = [this.productSelectItems[0].value];
		await this.fetchData(this.$props.stationId);
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		productSelectItems () {
			const productSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const product of this.store.getters.products) {
				const item = {
					text: `(${product.id}) ${product.title}`,
					value: product.id
				};

				if (_.findIndex(productSelectItems, item) === -1) {
					productSelectItems.push(item);
				}
			}

			return productSelectItems;
		},
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}

				return dateRange;
			}

			return "";
		},
		timeRange () {
			return `${this.filters.timeRange.start}, ${this.filters.timeRange.end}`;
		},
		icon () {
			if (this.filters.products.length === this.productSelectItems.length) return "mdi-close-box";
			if (this.filters.products.length !== this.productSelectItems.length && this.filters.products.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		totalSales () {
			return _.sumBy(this.filteredSalesItems, "qty");
		}
	},
	watch: {
		stationId: async function (newStationId) {
			await this.fetchData(newStationId);
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Request cancelled");
				this.axiosSource = axios.CancelToken.source();
			}
		}
	},
	methods: {
		checkIfHighlight (item: IProductStatSalesInfo) {
			if (moment(item.soldDate).isSame(item.productionDate)) {
				return "table__row__highlight_yellow";
			}

			return "";
		},
		async fetchData (stationId: string | undefined) {
			this.tableLoading = true;
			this.progressPercentage = 0;
			let totalLoaded = 0;

			if (stationId) {
				try {
					let localSalesItems: IProductStatSalesInfo[] = [];

					for (const product of this.filters.products) {
						const data: IProductStatSalesFilter = {
							grouping: this.filters.grouping,
							soldType: this.filters.soldType,
							filterBy: this.filters.dateFilterBy,
							date: {
								from: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
								until: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
							},
							time: {
								start: this.filters.timeRange.start,
								end: this.filters.timeRange.end
							},
							product,
							timezone: this.filters.timezone
						};
						const options: AxiosRequestConfig = {
							method: "POST",
							headers: {
								Authorization: `Bearer ${localStorage.getItem("token")}`
							},
							data,
							url: `${store.getters.serverURL}/product/stats/sales/${stationId}`,
							cancelToken: this.axiosSource.token
						};
						const res: AxiosResponse<IServerRES<IProductStatSalesInfo[]>> = await axios(options);
						if (res.data.err === ServerError.NO_ERROR) {
							localSalesItems = localSalesItems.concat(res.data.payload);
							totalLoaded += 1;
							this.progressPercentage = Math.floor((totalLoaded * 1.0 / this.filters.products.length) * 100);
						}
					}

					this.salesItems = localSalesItems;
				} catch (err) {
					console.error(err);
				}

				this.tableSortByUpdate();
				this.filterResults();
				this.generateChartData();
				this.showChartUpdate();
			}

			this.tableLoading = false;
			return totalLoaded === this.filters.products.length;
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		filterResults () {
			this.filteredSalesItems = JSON.parse(JSON.stringify(this.salesItems));
			this.filteredSalesItems.map((item) => {
				item.productionTime = item.productionDate;
				item.soldTime = item.soldDate;
			});
		},
		toggleSelectAllProducts () {
			if (this.filters.products.length === this.productSelectItems.length) {
				this.filters.products = [];
			}	else {
				for (const item of this.productSelectItems) {
					if (this.filters.products.indexOf(item.value) === -1) {
						this.filters.products.push(item.value);
					}
				}
			}
		},
		generateChartData () {
			const getMyColor = () => {
				const n = (Math.random() * 0xfffff * 1000000).toString(16);
				return `#${n.slice(0, 6)}`;
			};

			const newChardData: ChartData = {
				labels: [],
				datasets: []
			};

			const startDate = moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day");
			const endDate = moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day");

			switch (this.filters.grouping) {
				case ("Hourly"):
					for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "hour")) {
						if (newChardData.labels) {
							newChardData.labels.push(currDate.format("HH:mm"));
						}
					}
					break;

				default:
					for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "days")) {
						if (newChardData.labels) {
							newChardData.labels.push(currDate.format("DD/MM"));
						}
					}
			}

			const groupedItems = _.groupBy(this.filteredSalesItems, "productId");
			for (const key in groupedItems) {
				if (newChardData.datasets) {
					groupedItems[key].sort((a, b) => {
						if (this.filters.dateFilterBy === "Sold Date") {
							return moment(a.soldDate).diff(moment(b.soldDate));
						}

						return moment(a.productionDate).diff(moment(b.productionDate));
					});

					const data: number[] = [];
					switch (this.filters.grouping) {
						case ("Hourly"):
							for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "hour")) {
								let exists = false;
								if (this.filters.dateFilterBy === "Sold Date") {
									for (const item of groupedItems[key]) {
										if (moment(item.soldDate).format("YYYYMMDDHH") === currDate.format("YYYYMMDDHH")) {
											data.push(item.qty);
											exists = true;
										}
									}
								} else if (this.filters.dateFilterBy === "Production Date") {
									for (const item of groupedItems[key]) {
										if (moment(item.productionDate).format("YYYYMMDDHH") === currDate.format("YYYYMMDDHH")) {
											data.push(item.qty);
											exists = true;
										}
									}
								}

								if (!exists) {
									data.push(0);
								}
							}
							break;

						default:
							for (let currDate = moment(startDate); currDate.isSameOrBefore(endDate); currDate.add(1, "days")) {
								let exists = false;
								if (this.filters.dateFilterBy === "Sold Date") {
									for (const item of groupedItems[key]) {
										if (moment(item.soldDate).format("YYYYMMDD") === currDate.format("YYYYMMDD")) {
											data.push(item.qty);
											exists = true;
										}
									}
								} else if (this.filters.dateFilterBy === "Production Date") {
									for (const item of groupedItems[key]) {
										if (moment(item.productionDate).format("YYYYMMDD") === currDate.format("YYYYMMDD")) {
											data.push(item.qty);
											exists = true;
										}
									}
								}

								if (!exists) {
									data.push(0);
								}
							}
					}

					let color = this.store.state.dashboard.colorPresets[key];
					if (!color) {
						color = getMyColor();
					}
					newChardData.datasets.push({
						label: groupedItems[key][0].productName,
						data,
						borderColor: color,
						backgroundColor: color,
						fill: false
					});
				}
			}

			this.chartData.labels = newChardData.labels;
			this.chartData.datasets = newChardData.datasets;

			(this.$refs.myChart as InstanceType<typeof Chart>).updateChart();
		},
		showChartUpdate () {
			if (this.filters.grouping === "Total" || this.filters.grouping === "None") {
				this.showChart = false;
			} else {
				this.showChart = true;
			}
		},
		tableSortByUpdate () {
			if (this.filters.dateFilterBy === "Sold Date") {
				this.tableSortBy = "soldDate";
			} else {
				this.tableSortBy = "productionDate";
			}
		},
		async generateExcel (stationId: string | undefined) {
			if (await this.fetchData(stationId)) {
				const excelDoc: IProductStatSalesInfoCSV = [];

				for (const item of this.filteredSalesItems) {
					excelDoc.push({
						productId: item.productId,
						productName: item.productName || "Unknown",
						stationId: item.stationId,
						productionDate: moment(item.productionDate).format("DD/MM/YYYY"),
						productionTime: moment(item.productionTime).format("HH:mm"),
						soldDate: moment(item.soldDate).format("DD/MM/YYYY"),
						soldTime: moment(item.soldTime).format("HH:mm"),
						soldType: item.soldType,
						qty: item.qty.toString()
					});
				}

				const ws = XLSX.utils.json_to_sheet(excelDoc);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Sales");
				XLSX.writeFile(wb, "sales.xlsx");
			}
		}
	}
});
