
import Vue from "vue";

type ResolveFunction = (value: boolean) => void;
type RejectFunction = (value: Error) => void;

export default Vue.extend({
	name: "IframeDialog",
	data: () => ({
		dialog: false as boolean,
		resolve: null as ResolveFunction|null,
		reject: null as RejectFunction|null,
		title: "",
		url: ""
	}),
	methods: {
		open (title: string, url: string) {
			this.dialog = true;
			this.title = title;
			this.url = url;

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		clearPromise () {
			this.resolve = null;
			this.reject = null;
		},
		agree () {
			if (this.resolve) {
				this.resolve(true);
			}
			this.url = "";
			this.dialog = false;
			this.clearPromise();
		},
	},
});
