
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import * as XLSX from "xlsx";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { ICorrectionsReportJoin, ICorrectionsReportDisplayed, ICorrectionsReportsCSVEntry } from "@common/correctionsReport";
import { IStationOperator } from "@common/station";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

export default Vue.extend({
	name: "CorrectionsReportTab",
	props: {
		stationId: String
	},
	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			currentOperator: {} as IStationOperator,
			operators: [] as IStationOperator[],
			tableLoading: false as boolean,
			progressPercentage: 0 as number,
			headers: [] as TableHeader[],
			headersProduct: [
				new TableHeader("Barcode", "start", true, "correctedElementIdentifier"),
				new TableHeader("Corrected Element", "start", true, "elementType"),
				new TableHeader("Product Id", "start", true, "elementId"),
				new TableHeader("Product Name", "start", true, "elementName"),
				new TableHeader("Correction Date", "start", true, "correctedDate"),
				new TableHeader("Correction Time", "start", true, "correctedTime"),
				new TableHeader("Operator Name", "start", true, "operatorName"),
				new TableHeader("Correction Made", "start", true, "modifiedQty"),
				new TableHeader("Reason", "start", true, "reason")
			],
			headersReceptionBatch: [
				new TableHeader("Batch Number", "start", true, "correctedElementIdentifier"),
				new TableHeader("Corrected Element", "start", true, "elementType"),
				new TableHeader("Ingredient Id", "start", true, "elementId"),
				new TableHeader("Ingredient Name", "start", true, "elementName"),
				new TableHeader("Correction Date", "start", true, "correctedDate"),
				new TableHeader("Correction Time", "start", true, "correctedTime"),
				new TableHeader("Operator Name", "start", true, "operatorName"),
				new TableHeader("Correction Made", "start", true, "modifiedQty"),
				new TableHeader("Reason", "start", true, "reason")
			],
			headersIngredient: [
				new TableHeader("Barcode", "start", true, "correctedElementIdentifier"),
				new TableHeader("Corrected Element", "start", true, "elementType"),
				new TableHeader("Ingredient Id", "start", true, "elementId"),
				new TableHeader("Ingredient Name", "start", true, "elementName"),
				new TableHeader("Correction Date", "start", true, "correctedDate"),
				new TableHeader("Correction Time", "start", true, "correctedTime"),
				new TableHeader("Operator Name", "start", true, "operatorName"),
				new TableHeader("Correction Made", "start", true, "modifiedQty"),
				new TableHeader("Reason", "start", true, "reason")
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			filteredCorrectionsReports: [] as ICorrectionsReportDisplayed[],
			filters: {
				selectedElement: store.state.dashboard.filters.elementType[0] as string,
				selectedOperator: {} as IStationOperator,
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
			},
			menus: {
				dateRange: false,
			},
			selectedRange: 3,
		};
	},
	created: async function () {
		await this.fetchData();

		const allOperatorsString: IStationOperator = {
			id: "-1",
			stationId: "",
			username: "",
			fullName: "All Operators"
		};

		this.operators.push(allOperatorsString);
		for (const operator of this.store.getters.operators) {
			if (operator.stationId === this.$props.stationId) {
				this.operators.push(operator);
			}
		}
		this.filters.selectedOperator = this.operators[0];

		// setup the tableHeader based on the corrected element
		this.updateTableHeader();
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}
				return dateRange;
			}
			return "";
		},
		totalCorrectionReports () {
			return this.filteredCorrectionsReports.length;
		}
	},
	watch: {
		stationId: async function () {
			await this.fetchData();
			const allOperatorsString: IStationOperator = {
				id: "-1",
				stationId: "",
				username: "",
				fullName: "All Operators"
			};

			this.operators = [];
			this.operators.push(allOperatorsString);
			for (const operator of this.store.getters.operators) {
				if (operator.stationId === this.$props.stationId) {
					this.operators.push(operator);
				}
			}
			this.filters.selectedOperator = this.operators[0];
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Operation canceled by the user.");
				this.axiosSource = axios.CancelToken.source();
			}
		}
	},
	methods: {
		async fetchData () {
			this.tableLoading = true;
			this.progressPercentage = 0;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					params: {
						operatorId: this.filters.selectedOperator.id,
						elementType: this.filters.selectedElement,
						dateBegin: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
						dateEnd: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate(),
						stationId: this.$props.stationId
					},
					url: `${store.getters.serverURL}/corrections-report/all-filtered`,
					cancelToken: this.axiosSource.token
				};
				const res: AxiosResponse<IServerRES<ICorrectionsReportJoin[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.filteredCorrectionsReports = [];
					res.data.payload.forEach((item) => {
						this.filteredCorrectionsReports.push({
							correctedElementIdentifier: item.correctedElementIdentifier,
							elementId: item.elementId,
							elementType: item.elementType,
							elementName: item.elementName,
							correctedDate: moment(item.date).format("DD/MM/YYYY"),
							correctedTime: moment(item.date).format("HH:mm"),
							modifiedQty: item.modifiedQty,
							operatorName: item.operatorName,
							reason: item.reason
						});
					});
				}
			} catch (err) {
				console.error(err);
			}

			this.updateTableHeader();
			this.tableLoading = false;

			return true;
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		updateTableHeader () {
			switch (this.filters.selectedElement) {
				case "Ingredient": {
					this.headers = this.headersIngredient;
					break;
				}
				case "Product": {
					this.headers = this.headersProduct;
					break;
				}
				default: {
					this.headers = this.headersReceptionBatch;
					break;
				}
			};
		},
		async generateExcel () {
			if (await this.fetchData()) {
				const excelDoc: ICorrectionsReportsCSVEntry[] = [];
				for (const item of this.filteredCorrectionsReports) {
					excelDoc.push({
						ingredientId: item.correctedElementIdentifier,
						elementId: item.elementId,
						elementType: item.elementType,
						elementName: item.elementName,
						correctionDate: item.correctedDate,
						correctionTime: item.correctedTime,
						correctionMade: item.modifiedQty.toString(),
						reason: item.reason,
						operatorName: item.operatorName
					});
				}

				const ws = XLSX.utils.json_to_sheet(excelDoc);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Product Scrapping");
				XLSX.writeFile(wb, "product-scrapping.xlsx");
			}
		},
		// Without the following function, the whole class will crash.
		//
		// Once you are done trying to 'fix' this issue,
		// and have realized what a terrible mistake that was,
		// please increment the following counter as a warning
		// to the next guy:
		//
		// total_hours_wasted_here = 1
		//
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		dummy (none: void) {
			// Never reached
		}
	}
});
