
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment-timezone";
import _ from "lodash";
import { ChartData } from "chart.js";
import * as XLSX from "xlsx";

import Chart from "@/components/common/Chart.vue";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IIngredientOpenInfoFilter, IIngredientReceptionInfo, IIngredientsReceptionInfo, IIngredientsReceptionInfoCSV } from "@common/ingredientOpen";

class TableHeader {
	constructor (
		public readonly text: string,
		public readonly align: string,
		public readonly sortable: boolean,
		public readonly value: string
	) {}
}

interface IngredientReceptionInfo extends IIngredientReceptionInfo {
	receptionTime?: Date;
	expirationTime?: Date;
}

export default Vue.extend({
	name: "IngredientReceptionTab",
	props: {
		stationId: String
	},
	components: {
		chart: Chart
	},
	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			search: "" as string,
			tableLoading: false as boolean,
			progressPercentage: 0 as number,
			headers: [
				new TableHeader("Ingredient Id", "start", true, "ingredientId"),
				new TableHeader("Ingredient Name", "start", true, "ingredientName"),
				new TableHeader("Weight", "start", true, "weight"),
				new TableHeader("Batch", "start", true, "batch"),
				new TableHeader("Reception Date", "start", true, "date"),
				new TableHeader("Reception Time", "start", true, "receptionTime"),
				new TableHeader("Expiration Date", "start", true, "dateExpire"),
				new TableHeader("Expiration Time", "start", true, "expirationTime"),
			],
			footerProps: {
				"items-per-page-options": [15, 30, 50, -1],
			},
			autoCompleteChipsLimit: 10,
			receptionItems: [] as IIngredientsReceptionInfo,
			filteredReceptionItems: [] as IngredientReceptionInfo[],
			filters: {
				grouping: store.state.dashboard.filters.grouping[0],
				ingredients: [] as string[],
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
				timeRange: {
					start: store.state.dashboard.timePresets.start[0],
					end: store.state.dashboard.timePresets.end[store.state.dashboard.timePresets.end.length - 1]
				},
				timezone: moment().format("Z"),
				cause: store.state.dashboard.filters.causes.ingredients[0]
			},
			menus: {
				dateRange: false,
				timeRange: false
			},
			selectedRange: 3,
			chartData: {
				labels: [],
				datasets: []
			} as ChartData
		};
	},
	created: async function () {
		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchOnlyUsedIngredients();
		}
		this.filters.ingredients = [this.ingredientSelectItems[0].value];
		await this.fetchData(this.$props.stationId);
	},
	computed: {
		formatTime () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) => moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		ingredientSelectItems () {
			const ingredientSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const ingredient of this.store.getters.ingredients) {
				const item = {
					text: `(${ingredient.id}) ${ingredient.name}`,
					value: ingredient.id
				};

				if (_.findIndex(ingredientSelectItems, item) === -1) {
					ingredientSelectItems.push(item);
				}
			}

			return ingredientSelectItems;
		},
		icon () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) return "mdi-close-box";
			if (this.filters.ingredients.length !== this.ingredientSelectItems.length && this.filters.ingredients.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		dateRange () {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}

				return dateRange;
			}

			return "";
		},
		timeRange () {
			return `${this.filters.timeRange.start}, ${this.filters.timeRange.end}`;
		}
	},
	watch: {
		stationId: async function (newStationId: string | undefined) {
			await this.fetchData(newStationId);
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Request cancelled by user.");
				this.axiosSource = axios.CancelToken.source();
			}
		}
	},
	methods: {
		async fetchData (stationId: string | undefined) {
			this.tableLoading = true;
			this.progressPercentage = 0;
			let totalLoaded = 0;

			if (stationId) {
				try {
					let localReceptionItems: IIngredientsReceptionInfo = [];
					for (const ingredient of this.filters.ingredients) {
						const data: IIngredientOpenInfoFilter = {
							date: {
								start: moment(this.filters.dateRange[0], "YYYY-MM-DD").startOf("day").toDate(),
								end: moment(this.filters.dateRange[1], "YYYY-MM-DD").endOf("day").toDate()
							},
							ingredientId: ingredient,
							stationId
						};
						const options: AxiosRequestConfig = {
							method: "POST",
							headers: {
								Authorization: `Bearer ${localStorage.getItem("token")}`
							},
							data,
							url: `${store.getters.serverURL}/ingredient-open/stats/reception`,
							cancelToken: this.axiosSource.token
						};
						const res: AxiosResponse<IServerRES<IIngredientsReceptionInfo>> = await axios(options);
						if (res.data.err === ServerError.NO_ERROR) {
							localReceptionItems = localReceptionItems.concat(res.data.payload);
							totalLoaded += 1;
							this.progressPercentage = Math.floor((totalLoaded * 1.0 / this.filters.ingredients.length) * 100);
						}
					}

					this.receptionItems = localReceptionItems;
				} catch (err) {
					console.error(err);
				}

				this.filterResults();
			}

			this.tableLoading = false;
			return totalLoaded === this.filters.ingredients.length;
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case ("Today"):
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Yesterday"):
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD")
					];
					break;
				case ("Last 7 Days"):
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last 30 Days"):
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("This Month"):
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD")
					];
					break;
				case ("Last Month"):
					this.filters.dateRange = [
						moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange = this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		toggleSelectAllIngredients () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) {
				this.filters.ingredients = [];
			}	else {
				for (const item of this.ingredientSelectItems) {
					if (this.filters.ingredients.indexOf(item.value) === -1) {
						this.filters.ingredients.push(item.value);
					}
				}
			}
		},
		filterResults () {
			this.filteredReceptionItems = JSON.parse(JSON.stringify(this.receptionItems));
			this.filteredReceptionItems.map((item) => {
				item.receptionTime = item.date;
				item.expirationTime = item.dateExpire;
				if (item.unit === "kg") {
					item.unit = "g";
				}

				return item;
			});
		},
		async generateExcel (stationId: string | undefined) {
			if (await this.fetchData(stationId)) {
				const excelDoc: IIngredientsReceptionInfoCSV = [];

				for (const item of this.filteredReceptionItems) {
					excelDoc.push({
						ingredientId: item.ingredientId,
						ingredientName: item.ingredientName,
						stationId: item.stationId,
						batch: item.batch,
						dateReception: moment(item.date).format("DD/MM/YYYY"),
						timeReception: moment(item.receptionTime).format("HH:mm"),
						dateExpire: moment(item.dateExpire).format("DD/MM/YYYY"),
						timeExpire: moment(item.expirationTime).format("HH:mm"),
						weight: item.weight,
						unit: item.unit
					});
				}

				const ws = XLSX.utils.json_to_sheet(excelDoc);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Ingredient Reception");
				XLSX.writeFile(wb, "ingredient-reception.xlsx");
			}
		}
	}
});
