import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":1000,"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary","dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.title))])],1),_c(VCardText,[_c('iframe',{attrs:{"src":_vm.url,"width":"100%","height":"600"}})]),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("check")]),_vm._v("Ok ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }