import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.mixin({
	data: function () {
		return {
			get store () {
				return store;
			}
		};
	}
});

new Vue({
	router,
	store: store.original,
	vuetify,
	render: h => h(App)
}).$mount("#app");
