import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "material-design-icons-iconfont/dist/material-design-icons.css";

// Custom Icons
import Weight from "@/components/common/icons/general_info/Weight.vue";
import NutritionalInformation from "@/components/common/icons/NutritionalInformation.vue";
import GeneralInfo from "@/components/common/icons/GeneralInfo.vue";
import Products from "@/components/common/icons/Products.vue";
import Ingredients from "@/components/common/icons/Ingredients.vue";
import Allergens from "@/components/common/icons/Allergens.vue";
import ColdTemperature from "@/components/common/icons/ColdTemperature.vue";
import HotTemperature from "@/components/common/icons/HotTemperature.vue";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#003366",
				success: "#66CC00",
				error: "#C3240E"
			},
		},
	},
	icons: {
		values: {
			weight: {
				component: Weight
			},
			nutritionalInformation: {
				component: NutritionalInformation
			},
			generalInfo: {
				component: GeneralInfo
			},
			products: {
				component: Products
			},
			ingredients: {
				component: Ingredients
			},
			allergens: {
				component: Allergens
			},
			coldTemperature: {
				component: ColdTemperature
			},
			hotTemperature: {
				component: HotTemperature
			}
		}
	}
});
